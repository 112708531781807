<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary"></v-progress-linear>

        <v-row>
          <v-spacer></v-spacer>
          <ca-lang-selector class="me-3" />
        </v-row>

        <v-card-text class="text-center">
          <v-avatar size="60" class="mb-4">
            <img src="@/assets/images/logo.svg" alt="" />
          </v-avatar>

          <h6 class="text--disabled font-weight-medium mb-10">
            {{ $t("auth.password_recovery") }}
          </h6>

          <ca-alert v-if="errorMessage" type="error"><div v-html="errorMessage"></div></ca-alert>
          <ca-alert v-if="successMessage" type="success">
            {{ $t("auth.recovery_password_link_sent") }}
          </ca-alert>

          <v-form ref="form">
            <div v-show="displayForm">
              <v-text-field
                v-model="email"
                :label="$t('auth.email')"
                :rules="emailRules"
                required
                :error-messages="fieldsErrors.email"
              />

              <captcha v-model="captcha" :is-debug="debugCaptcha" />

              <v-btn class="mb-4" block color="primary" dark @click="formSubmit">
                {{ $t("common.send") }}
              </v-btn>
            </div>

            <div class="d-flex justify-around flex-wrap">
              <v-btn text small color="primary" class="mb-2" :to="{ name: 'login' }">
                {{ $t("auth.sign_in") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>

<script>
import Captcha from "@/views/components/Captcha.vue";
import CaAlert from "@/views/components/Alert";

export default {
  name: "Login",
  components: { CaAlert, Captcha },

  metaInfo() {
    return {
      title: this.$t("titles.password_recovery")
    };
  },

  data() {
    return {
      displayForm: true,
      successMessage: false,
      errorMessage: null,
      email: "",
      debugCaptcha: false,
      captcha: false,
      loading: false,
      emailRules: [
        v => !!v || this.$t("auth.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("auth.email_must_be_valid")
      ],
      fieldsErrors: {
        email: [],
        captcha: null
      },
      captchaErrorMessage: this.$t("auth.enter_captcha")
    };
  },

  mounted() {
    this.loadCaptchaState()
  },

  methods: {
    formSubmit() {
      if (this.loading) {
        return;
      }

      this.errorMessage = null;
      this.successMessage = false;

      if (!this.$refs.form.validate()) {
        return;
      }

      if (!this.captcha && !this.debugCaptcha) {
        this.errorMessage = this.captchaErrorMessage;
        return;
      }

      this.loading = true;
      this.errorMessage = null;
      this.emptyFieldErrors();

      let data = {
        email: this.email
      };

      data["captcha"] = this.debugCaptcha ? '1' : window.grecaptcha.getResponse();

      this.axios
        .post("auth/request-restore-password", data)
        .then(() => {
          this.loading = false;
          this.successMessage = true;
          this.displayForm = false;
        })
        .catch(error => {
          const response = error.response;

          this.loading = false;
          this.captcha = false;

          if (response.status === 422) {
            this.fieldsErrors = response.data.errors;

            if (this.fieldsErrors.captcha) {
              this.errorMessage = this.captchaErrorMessage;
            }
          } else if (response.status === 429) {
            this.errorMessage = response.data.message;
          } else if (response.status === 400) {
            this.fieldsErrors.email = response.data.error;
          }
        });
    },

    emptyFieldErrors() {
      this.fieldsErrors = {
        email: [],
        password: [],
        captcha: null
      };
    },

    loadCaptchaState() {
      this.axios
        .get("/captcha/login")
        .then(response => {
          this.showCaptcha = response.data.active;
          this.debugCaptcha = response.data.debug;
        })
        .catch(() => {
          alert("Some error happened");
        });
    }
  }
};
</script>
